<template>
  <main class="mt-6 mb-6 mr-4 p-5 bg-white">
    <h1>Страница триггеры</h1>
    <input type="text" class="border w-10" />
    <input placeholder="Название" class="border" v-model="triggerName" />
    <button class="bg-green-200">Сохранить</button>
    <p>{{ triggerName }}</p>

    <div
      class="
        flex flex-row
        p-4
        mt-4
        justify-between
        items-center
        mx-auto
        border
        bg-blue-50
        shadow-xl
        rounded-3xl
      "
    >
      <div class="flex">
        <input class="mr-4 text-xl border bg-blue-50" value="😊" size="1" />
        <input class="mr-4 text-xl" placeholder="Название.." />
      </div>
      <div class="flex">
        <span class="text-red-600 bg-red-100 p-2">del</span>
      </div>
    </div>

    <div
      class="
        flex flex-row
        p-4
        mt-4
        justify-between
        items-center
        mx-auto
        border
        bg-blue-50
        shadow-xl
        rounded-3xl
      "
    >
      <div class="flex">
        <span class="mr-4 text-xl">😊</span>
        <span class="mr-4 text-xl">Стресс</span>
      </div>
      <div class="flex">
        <span class="text-red-600 bg-red-100 p-2">del</span>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {
      triggerName: "",
    };
  },
};
</script>
